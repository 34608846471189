// extracted by mini-css-extract-plugin
export var ftParaGraph = "footer-module--ftParaGraph--ILcFB";
export var FtImg = "footer-module--FtImg--vFCoU";
export var htListHighLight = "footer-module--htListHighLight--6Xysv";
export var ftboxnline = "footer-module--ftboxnline--aIE5+";
export var ftList = "footer-module--ftList--Wucnr";
export var ftbox = "footer-module--ftbox--3h7qa";
export var ftMianContainer = "footer-module--ftMianContainer--5VWLk";
export var ftMianContainermob = "footer-module--ftMianContainermob--D8B8i";
export var footerBox = "footer-module--footerBox--WZ2OQ";
export var footerLogoLink = "footer-module--footerLogoLink--YxoS5";
export var footerText = "footer-module--footerText--UzaqF";
export var footerLinkList = "footer-module--footerLinkList--B8xSw";
export var lastDiv = "footer-module--lastDiv--I06r0";
export var footList = "footer-module--footList--lpU5k";
export var footerBorderRight = "footer-module--footerBorderRight--BBMgr";
export var singlecobrandpage = "footer-module--singlecobrandpage--eA4XX";